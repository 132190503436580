import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Metaverse = () => {
  return (
    <>
      <Layout>
        <Seo title="  MFD for electronically enhanced Soft goods" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="xl:pr-96">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-medium">
                  MFD for electronically enhanced Soft goods:
                  {/* <div> Smart Textiles</div> */}
                </div>
                <div className="text-2xl mt-4">
                  Ross D Alcazar – Chief Operating Officer, Softmatter
                  {/* <div>and smart textiles</div> */}
                </div>
                <div className="mt-4">18th August 2022</div>
              </div>
              <div className=" my-7  py-10">
                <div>
                  <StaticImage
                    alt="Softmatter"
                    src="../../images/news/mfd.jpg"
                  />
                </div>
                <div className="my-4">
                  The topic of productization and some of our challenges today
                  is at the forefront of our evolution. By finding ways to
                  continually innovate and how we improve by building new
                  products and rolling them out to mass production as
                  efficiently as possible. In addition, there are new obstacles
                  to combining the precision requirements of hard goods with the
                  lenient attributes of soft goods.
                </div>
                <div className="mb-4">
                  Although from early examples and highlights of Productization
                  and Learning from the OEMs of Silicon valley, we are
                  replicating best practices and models.
                </div>
                <div className="mb-4">
                  There have been many tech start-ups and hardware companies
                  that have been borne from Silicon valley in the last few
                  decades, and majority of them still have the same fundamental
                  ramp up issues today. Hardware is always evolving and
                  changing, and innovation in Silicon valley has no end.
                  Therefore hardware manufacturers should never be complacent
                  towards any certain type of manufacturing methods. For
                  example, Cell phones and traditional PCBA making of the past
                  have given way to 3D printing technologies used today. 3D
                  printing becomes an enabler to accelerate time to market or
                  flexible manufacturing. Soft goods commonly fabricated by sew
                  and stitch are viewed as the next opportunities for
                  sustainability and automation. Particularly to remove waste in
                  our processes and reduce human error.
                </div>
                <div className="mb-4">
                  Products being designed these days continue to push the
                  envelope of traditional manufacturing rules and not always
                  fits the standard DFM DFX guidelines. Merging both hardgoods
                  and softgoods to the highest standards in each of their
                  categories is difficult.
                </div>
                <div className="mb-4">
                  Often designers are opted to degrade their own output to
                  realize mass production on existing manufacturing capital
                  equipment. These considerations are typically described in the
                  design stage as “Design for Manufacturing” DFM where the
                  constraints on production equipment limits the designer to a
                  specific approach early in the design. In which case, more
                  often restricts true creativity and possibilities for
                  significant innovative products.
                </div>

                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Emergence of Web 3.0
                  </div>
                  <div className="mb-4">
                    How did the need for Web 3.0 arise? This is important to
                    consider, as it helps understand the opportunities that are
                    available in Web 3.0.
                  </div>
                  <div className="mb-4">
                    The current age of the internet, referred to as Web 2.0, has
                    fostered a culture of distrust due to the lack of
                    transparency and data security, particularly concerning
                    user-generated content. Due to the centralized nature in
                    which the internet currently operates, social media
                    companies such as Instagram, Facebook and YouTube have the
                    freedom to ban users and restrict content; hence users have
                    limited control over their online personas and digital
                    assets. Therefore, barriers to earn revenue on
                    user-generated content where users may lack control of their
                    own content, is a major source of frustration. Furthermore,
                    the current centralized aspect of the internet allows large
                    corporate entities to profit using valuable user data. An
                    example of an incident which led to the skepticism towards
                    entities such as Facebook, is the data misuse scandal, in
                    which, personal data of Facebook users was collected by a
                    consulting firm Cambridge Analytica, for political
                    targeting.
                  </div>
                  <div className="mb-4">
                    However, as the internet continues to evolve, Web 3.0
                    emerges, and may be understood as an ongoing iteration that
                    operates on a decentralized online network, which offers
                    users the ability to take ownership of their own data and
                    other digital assets. An example of decentralized protocols,
                    used by Web 3.0, are blockchains, which are leveraged for
                    cryptocurrency transactions. The adoption of Web 3.0 grants
                    users the opportunity to monetize their creations, while
                    owning the process end-to-end, without requiring permission
                    from big tech companies. Further, with the advancement of
                    artificial intelligence, predictive analytics can be
                    deployed on the user’s end to distinguish between legitimate
                    and fraudulent actions, thus providing users with accurate
                    information for decision-making.
                  </div>
                  <div className="mb-4">
                    The use of Web 3.0 technology together with the Metaverse,
                    will enable companies to expand to a new domain in the
                    internet environment, thus bringing about a multitude of
                    revenue-generating opportunities.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/mfd/mfd-inner.jpg"
                    />
                  </div>
                  <div className="mb-4 pt-5">
                    Hence MFD or Manufacturing For Design is a terminology
                    recently implemented by the most demanding brands. It’s a
                    framework whereby product design creativity is the primary
                    approach and stimulus to force and rethink manufacturing
                    process approaches. Manufacturing has to adjust by
                    developing and inventing processes to keep up with
                    customer’s new requirements and a designer’s unlimited
                    boundaries. New equipment, methods, and transferring of
                    technique from proven industries are essential.
                  </div>
                  <div className="mb-4">
                    This is not a replacement for DFM, but rather an extension
                    that would continue to question and “challenge” the norm.
                    Its clear that existing machines could not be abandoned, but
                    consider an intermediate step to help enhance existing
                    capital, leverage of partnerships, to achieve new
                    requirements.
                  </div>
                  <div className="mb-4">
                    The blending of MFD and DFM strategies (a MFD|DFM approach)
                    can support to feasible evolution of manufacturing, and
                    ultimately disruptive process innovation, defined as a
                    rethinking of manufacturing rather than just improvement on
                    existing solutions.
                  </div>
                  <div className="mb-4">
                    By doing so, Softmatter will continue to position ourselves
                    as a key provider of embedded electronic value chain leader.
                    and the go to partner for electronic enhanced softgoods.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Metaverse
